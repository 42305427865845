import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Engage With God</h1>
      <p>A VenHuizen Family Minstry</p>
    </div>
  );
}

export default App;
